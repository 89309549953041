// Checking if Object Not Empty
export const isNotEmpty = (x) => {
  if (x instanceof Object) {
    return Object.entries(x).length > 0;
  }
  return !!x;
};

// Checking if Object Is Empty
export const isEmpty = (x) => !isNotEmpty(x);

// Convert Image to base64 code
export const convertImageToBase64 = (event, callback) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    callback({ image: reader.result });
  };
  reader.readAsDataURL(file);
};
