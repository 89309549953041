import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ProfileSvg from '../../../../icons/svg/ProfileSvg';
import dictionary from '../../../../utils/dictionary';
import { color, flex, font, sizing } from '../../../../utils/style';
import { freespinGetAvatar, freespinUploadAvatar } from '../../../../utils/api/freespin';
import { convertImageToBase64, isEmpty, isNotEmpty } from '../../../../utils/ui-api-helper';
import { StoreContext } from '../../../../store';
import { setUserAvatar, uploadUserAvatar } from '../../../../store/actions';
import UserAvatar from '../../../../icons/avatar/UserAvatar';
import Loader from '../../../shared/Loader';

const Wrap = styled.div`
  width: 256px;
  height: 96px;
  border-radius: 4px;
  margin: 25px 0px;  
  cursor: pointer;
  padding: 0px 15px;
  position: relative;
  
  background-color: ${color.transparentWhite05};
  
  ${flex};
  justify-content: flex-start;
  & input {
    position: absolute;
    width: 100%;
    height: 100%;
    text-indent: -999em;
    outline: none;
    color: transparent;
    background: transparent;
    cursor: pointer;
  }
  
  & svg,
  & .loader,
  & .profile-avatar{
    width: 69px;
    height: 69px;
    margin-right: 15px;
  }
  
  & h3 {
    margin: 0;
    padding: 0;
    
    font-family: ${font.default};
    font-size: ${sizing.normal};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: ${color.transparentWhite5};
  }
  
`;

const UploadAvatar = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [state, dispatch] = useContext(StoreContext);
  const { userAvatar } = state.profile;
  const { setAvatar } = state.profile;
  // console.log(state, setAvatar);

  useEffect(() => {
    if (!userAvatar) {
      // get user avatar - if it not exists
      setUserAvatar(dispatch)
        .then(() => setTimeout(() => {
          freespinGetAvatar()
            .then(() => window.location.reload());
        }, 100));
      setIsLoaded(true);
    } else {
      // setUploadNewAvatar(userAvatar);
      setIsLoaded(false);
    }
  }, [userAvatar, setAvatar]);

  const onChangeAvatarHandler = (event) => {
    // Convert image to base64 format and send it to BE
    const avatarImage = event.target.files[0];
    const imageSize = ((avatarImage.size / 1024) / 1024).toFixed(4);
    const maxMbForImage = 0.7;

    if (imageSize >= maxMbForImage) {
      return false;
    }

    if (avatarImage && avatarImage.type.includes('image/')) {
      convertImageToBase64(event, freespinUploadAvatar);
    }

    dispatch(uploadUserAvatar());
  };

  const loader = isLoaded && <div className="loader"><Loader /></div>;

  return (
    <Wrap>
      {!loader && userAvatar.length === 0 ? (
        <ProfileSvg />
      ) : (
        <UserAvatar avatar={userAvatar} />
      )}
      <h3>{dictionary.uploadAvatar} <br /> (max size: 700 kb)</h3>
      <input type="file" onChange={(e) => onChangeAvatarHandler(e)} accept="image/*" />
    </Wrap>
  );
};

export default UploadAvatar;
