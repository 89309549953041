import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, resetList } from '../../../utils/style';
import { makeKey } from '../../../utils/system';
import { StoreContext } from '../../../store';
import { setActiveSubTab } from '../../../store/actions';

const Wrap = styled.ul`
  ${resetList};
  width: 100%;
  height: 40px;
  position: relative;
  
  ${flex};
  justify-content: flex-start;
  align-items: flex-start;
  
  
  & li {
    height: 100%;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
  
    & span {
      font-family: Kanit;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(255,255,255,0.6);
    }
    
    &.active {
      & span {
        color: #ffffff;
      }
      
      & .li-border {
        display: block;
      }
    }
    
    &.ul-border, & .li-border {
      width: 100%;
      height: 6px;
      background-color: rgba(255,255,255,0.2);
      border-radius: 4px;
      
      position: absolute;
      left: 0;
      top: calc(100% - 6px);
    }
    
    & .li-border {
      box-shadow: 0 3px 6px 0 #000d13, 0 0 10px 0 #0090fe, inset 0 -1px 1px 1px #0d2187, inset 0 -1px 3px 1px #0ca5ff;
      background-color: #8ddeff;
      display: none;
      
      top: calc(100% - 5px);
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Menu = ({ items = [] }) => {
  const [state, dispatch] = useContext(StoreContext);

  const [currentItem, setCurrentItem] = useState('');
  useEffect(() => {
    setCurrentItem('');
  }, [items]);

  const menuChange = (item) => {
    setCurrentItem(item);
    setActiveSubTab(dispatch, makeKey(item));
  };

  const isCurrent = (item) => (
    items.length > 1 &&
      !currentItem
      ? makeKey(items[0]) === makeKey(item)
      : makeKey(item) === currentItem
  );

  return (
    <>
      {
        items.length > 0 && (
          <Wrap>
            {
              items?.map((item) => (
                <li
                  className={isCurrent(item) && 'active'}
                  onClick={(e) => menuChange(makeKey(item))}
                >
                  <span>{item}</span>
                  <div className="li-border" />
                </li>
              ))
            }
            <li className="ul-border" />
          </Wrap>
        )
      }
    </>
  );
};

export default Menu;
