import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import ProfileSvg from '../../icons/svg/ProfileSvg';
import { breakpoints, color, flex } from '../../utils/style';
import Bonuses from '../Auth/Bonuses';
import Balance from '../Auth/Balance';
import ProfileDivider from '../shared/ProfileDivider';
import ProfileButton from './ProfileButton';
import IcomoonIcon from '../shared/IcomoonIcon';
import Menu from './Menu';
import dictionary from '../../utils/dictionary';
import UserAvatar from '../../icons/avatar/UserAvatar';
import { useModalOpen } from '../shared/Modal';

const Wrap = styled.div`
  width: 24%;
  height: 488px;
  background-color: ${color.darkPurple};
  border-radius: 4px;
  padding-top: 20px;

  ${flex};

  & .container {
    width: 100%;
    height: 100%;
  }

  & .profile-values,
  & .profile-buttons {
    padding: 0 15px;
  }

  & .profile-head {
    width: 100%;

    ${flex};
    flex-direction: column;

    & span {
      margin-top: 10px;
    }
    & .profile-avatar {
      width: 69px;
      height: 69px;
    }
  }

  & .profile-values {
    ${flex};
    justify-content: space-between;

    & div {
      margin: 0 0;
    }

    & .bonuses,
    & .balance {
      width: 50%;
    }

    & .balance {
      background-color: transparent;
      padding-left: 15px;

      & * {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  & .profile-buttons {
    ${flex};
    justify-content: space-between;
    margin-top: 20px;

    & .button {
      width: 120px;
      max-width: 100%;
      height: 44px;
      background-color: ${color.transparentWhite2};

      &:hover {
        background-color: ${color.activeBlue};
      }
    }
  }

  & .profile-menu-wrap {
    & .profile-menu {
      display: block;
      position: relative;
      background-color: transparent;
      margin-top: 30px;

      & .buttons {
        display: none;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: max-content;
    padding-bottom: 16px;

    & .profile-menu-wrap {
      display: none;
    }
  }
`;

const ProfilePanel = () => {
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;
  const { userAvatar } = state.profile;

  const openWithdraw = useModalOpen('withdraw-popup');
  const openDeposit = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/banking/';
    }
  };
  const copyUsername = () => {
    navigator.clipboard.writeText(userName);
    alert(dictionary.copiedToClipboard);
  };

  return (
    <Wrap>
      <div className="container">
        <div className="profile-head">
          {userAvatar.length === 0 ? <ProfileSvg width={69} height={69} /> : <UserAvatar avatar={userAvatar} />}
          <span onClick={copyUsername}>{userName ?? 'No Name'}</span>
        </div>
        <div className="profile-values">
          <Bonuses className="bonuses" />
          <ProfileDivider />
          <Balance className="balance" />
        </div>
        <div className="profile-buttons">
          <ProfileButton className="button" onClick={openDeposit}>
            <IcomoonIcon icon="purse" size={24} />
            <span>{dictionary.deposit}</span>
          </ProfileButton>
          <ProfileButton className="button" onClick={openWithdraw}>
            <IcomoonIcon icon="money-hand" size={24} />
            <span>ข้อมูลการถอน</span>
          </ProfileButton>
        </div>
        <div className="profile-menu-wrap">
          <Menu />
        </div>
      </div>
    </Wrap>
  );
};

export default ProfilePanel;
