import React from 'react';
import styled from '@emotion/styled';

const Wrap = styled.div`
  width: 1px;
  background-color: #ffffff;
  opacity: 0.2;
  box-shadow: -20px 20px 44px 0 rgba(195, 70, 255, 0.11), -20px 20px 14px 0 rgba(189, 66, 250, 0);
`;

const ProfileDivider = ({ height = 28 }) => (<Wrap className="divider" style={{ height: `${height}px` }} />);

export default ProfileDivider;
