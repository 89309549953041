import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Menu from './Menu';
import { StoreContext } from '../../../store';
import TabSwitcher from './TabSwitcher';
import { border, breakpoints } from '../../../utils/style';
import MobileMenu from './MobileMenu';

const Wrap = styled.div`
  width: 73%;
  height: 520px; // TODO : after adding upload avatar API endpoint change this to 520px
  background-color: #141d44;
  border-radius: 4px;
  padding: 30px 20px;
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: max-content;
    padding: 0 0;
    margin-top: 16px;
    background-color: transparent;
  }
`;

const Info = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  & .history-table {
    border-radius: ${border.borderRadius};
    padding: 15px;
    .bet-results {
      height: 84.5%;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    height: max-content;
    margin-top: 16px;
  }
`;

const InfoPanel = () => {
  const [state] = useContext(StoreContext);
  const {
    profile: { menus, activeTab },
  } = state;

  return (
    <Wrap>
      <Menu items={menus[activeTab]} />
      <MobileMenu items={menus[activeTab]} />
      <Info>
        <TabSwitcher tab={activeTab} />
      </Info>
    </Wrap>
  );
};

export default InfoPanel;
