import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import dictionary from '../../../../utils/dictionary';
import {
  border,
  breakpoints,
  color,
  flex,
  font,
  formsAutoFillingStyles,
  redButton,
  resetButton,
  sizing,
} from '../../../../utils/style';
import { freespinLogin, freespinResetPassword } from '../../../../utils/api/freespin';
import { clearToken, getUserName, setNewPass, setToken } from '../../../../utils/api';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -30px;
  & .change-pass-form {
    height: 100%;
    width: max-content;
    ${formsAutoFillingStyles};

    & .field-wrap {
      width: max-content;
      height: max-content;

      ${flex};
      align-items: flex-end;

      & input {
        width: 256px;
        height: 44px;

        border-radius: 4px;
        border: solid 1px ${color.transparentWhite4};
        background-color: transparent;
        padding-left: 15px;
        outline: none;
        margin-top: 28px;

        font-family: ${font.default};
        font-size: ${sizing.normal};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        color: ${color.white};

        &::placeholder {
          color: ${color.transparentWhite5};
        }
      }

      &.error {
        & input {
          border-color: #f33b74;
        }
      }

      & .required {
        height: 44px;
        ${flex};

        &:before {
          content: '*';
          margin-left: 11px;

          font-family: ${font.default};
          font-size: ${sizing.default};
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: ${color.transparentWhite5};
        }

        &.invisible {
          display: none;
        }

        &.visible {
          display: flex;
        }

        &.error {
          &:before {
            color: #f33b74;
          }
        }
      }
    }

    & .note {
      margin-top: 8px;

      font-family: ${font.default};
      font-size: ${sizing.text};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: ${color.transparentWhite5};
    }

    & .submit-button {
      ${resetButton};
      ${redButton};

      margin-top: 16px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    background-color: #141d44;
    border-radius: ${border.borderRadius};
    padding: 0 16px 46px 16px;
    margin-top: 0;

    ${flex};
    flex-direction: column;

    & .field-wrap {
      position: relative;

      & .required {
        position: absolute;
        right: 14px;
      }
    }
  }
`;

const MsgWrap = styled.div``;

const ChangePasswordForm = () => {
  const [formStatus, setFormStatus] = useState({
    status: false,
    message: null,
  });

  const initialValues = {};

  const submit = (values, { resetForm }) => {
    if (values.newPassword !== values.confirmPassword) return false;

    freespinLogin(getUserName(), values.currentPassword)
      .then((json) => {
        if (!json || !json.token) {
          setFormStatus((prevState) => ({
            ...prevState,
            status: false,
            message: !json.code
              ? dictionary.formBagRequest
              : json.code.includes('incorrect_password')
                ? dictionary.formIncorrectPassword
                : dictionary.formBagRequest,
          }));
          return false;
        }

        clearToken();
        setToken(json.token);

        return freespinResetPassword();
      })
      .then((json) => {
        if (json.error) {
          setFormStatus((prevState) => ({
            ...prevState,
            status: false,
            message: json.message,
          }));
          return false;
        }

        if (!json.reset_link) {
          setFormStatus((prevState) => ({
            ...prevState,
            status: false,
            message: dictionary.formBagRequest,
          }));
          return false;
        }

        setFormStatus((prevState) => ({
          ...prevState,
          status: true,
          message: dictionary.formSentSuccessfully,
        }));
        resetForm({});

        setNewPass(values.newPassword);

        window.location.href = json.reset_link
          .replace('https://s-bobet.com', '')
          .replace('/reset_password/', '/reset-password/');
      })
      .catch((err) => {
        console.error('Check Password request: ', err);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required(dictionary.formRequiredField),
        newPassword: Yup.string().required(dictionary.formRequiredField),
        confirmPassword: Yup.string().required(dictionary.formRequiredField),
      })}
    >
      {({ errors, touched, isValid, values }) => (
        <Wrap>
          {/* TODO : after adding profile upload avatar API - add Upload avatar component */}

          <Form className="change-pass-form">
            {/* Message Wrapper */}
            {formStatus.message && (
              <MsgWrap>
                <div className={formStatus.status ? 'success' : 'error'}>
                  <span>{formStatus.message}</span>
                </div>
              </MsgWrap>
            )}

            {/* Current Password */}
            <div className="field-wrap">
              <Field
                type="password"
                name="currentPassword"
                className="input-field"
                placeholder={dictionary.currentPassword}
                value={values.currentPassword || ''}
                autoComplete="on"
              />
              <div className={`required ${errors.currentPassword && 'error'}`} />
            </div>

            {/* New Password */}
            <div className="field-wrap">
              <Field
                type="password"
                name="newPassword"
                className="input-field"
                placeholder={dictionary.newPassword}
                value={values.newPassword || ''}
                autoComplete="on"
              />
              <div className={`required ${errors.newPassword && 'error'}`} />
            </div>

            {/* ConfirmPassword */}
            <div className={`field-wrap ${values.confirmPassword !== values.newPassword && 'error'}`}>
              <Field
                type="password"
                name="confirmPassword"
                className="input-field"
                placeholder={dictionary.confirmPassword}
                value={values.confirmPassword || ''}
              />
              <div className={`required ${errors.confirmPassword && 'error'}`} />
            </div>

            <div className="note">
              <span>* ช่องที่ต้องเติม</span>
            </div>

            <button className="submit-button" type="submit">
              เปลี่ยนรหัสผ่าน
            </button>
          </Form>
        </Wrap>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
