import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import { setBalance } from '../../store/actions';
import { breakpoints, font } from '../../utils/style';
import { freespinBalance } from '../../utils/api/freespin';

const GameBalanceComponent = styled.div`
  width: 144px;
  height: 44px;
  border-radius: 4px;
  background-color: #0d1430;
  padding-left: 10px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .title {
    opacity: 0.5;
    font-size: 12px;
    font-weight: ${font.medium};
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #d4daf8;
    text-transform: uppercase;
  }
  & .balance {
    font-size: 16px;
    font-weight: ${font.medium};
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffd10a;
  }

  &.header {
    width: max-content;
    height: 100%;
    padding-left: 0;
    margin-right: 0;
    margin-left: 16px;

    & .balance {
      text-align: left;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 128px;
    margin: 0;
  }
`;

const Balance = ({ className }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { user: { balance } = {} } = store;

  const [updateTime, setUpdateTime] = useState(new Date().getTime());

  useEffect(() => {
    if (balance) return () => {};

    freespinBalance()
      .then((json) => {
        setBalance(dispatch, json.balance ?? '0.00');
      })
      .catch((err) => {
        console.error('Balance request: ', err);
      });
  }, [updateTime]);

  // Updating balance every hour
  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTime(new Date().getTime());
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <GameBalanceComponent className={className || ''}>
      <span className="title">การธนาคาร</span>
      <span className="balance">{balance || '...'} ฿</span>
    </GameBalanceComponent>
  );
};

export default Balance;
