import React, { useCallback, useState, useContext } from 'react';
import styled from '@emotion/styled';
import IcomoonIcon from '../../shared/IcomoonIcon';
import { border, breakpoints, flex, resetList } from '../../../utils/style';
import { makeKey } from '../../../utils/system';
import { StoreContext } from '../../../store';
import { setActiveSubTab } from '../../../store/actions';

const Wrap = styled.div`
  width: 100%;
  height: 40px;
  border-radius: ${border.borderRadius};
  position: relative;
  overflow: hidden;
  z-index: 1;

  &.open {
    overflow: unset;
    & .arrow {
      transform: rotate(180deg);
    }
  }

  & .arrow {
    width: 42px;
    height: 100%;
    transition: 0.3s;

    ${flex};

    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Menu = styled.ul`
  ${resetList};

  width: 100%;
  background-color: #141d44;
  border-radius: ${border.borderRadius};

  & li {
    width: 100%;
    height: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 42px;

    ${flex};
    justify-content: flex-start;

    &:first-of-type {
      border-top: none;

      & > span {
        color: #ffffff;
      }
    }

    & > span {
      font-family: Kanit;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const MobileMenu = ({ items = [] }) => {
  const [state, dispatch] = useContext(StoreContext);

  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  // Menu actions
  const menuOpen = () => setIsOpen((open) => !open);
  const itemSetCurrent = useCallback((item) => {
    setCurrentItem(makeKey(item));
    setActiveSubTab(dispatch, makeKey(item));
  }, []);

  // Preparing items
  const filteredItems = items.filter((item) => makeKey(item) !== currentItem);
  const filteredCurrentItem = items.filter((item) => makeKey(item) === currentItem);

  return (
    <>
      {items.length > 0 ? (
        <Wrap className={isOpen && 'open'} onClick={menuOpen}>
          {items.length > 1 && (
            <div className="arrow">
              <IcomoonIcon icon="dropdown-arrow" color="" className="" size={12} />
            </div>
          )}
          <Menu>
            {filteredCurrentItem.length > 0 && (
              <li key={makeKey(filteredCurrentItem[0])}>
                <span>{filteredCurrentItem[0]}</span>
              </li>
            )}
            {filteredItems?.map((item) => (
              <li key={makeKey(item)} onClick={() => itemSetCurrent(item)}>
                <span>{item}</span>
              </li>
            ))}
          </Menu>
        </Wrap>
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileMenu;
