import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { breakpoints, flex, resetList } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';
import { freespinLogout } from '../../utils/api/freespin';
import { StoreContext } from '../../store';
import { setUser, setProfileMenu } from '../../store/actions';

const Wrap = styled.ul`
  ${resetList};

  width: 100%;
  height: 82px;
  background-color: #141d44;
  margin-top: 16px;
  border-radius: 4px;
  overflow: hidden;

  ${flex};
  justify-content: space-between;

  & * {
    outline: none;
  }

  & > li {
    height: 100%;
    width: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 9px 10px;

    &:first-of-type {
      border-left: none;
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.1);
    }

    & > a {
      width: 100%;
      height: 100%;

      ${flex};
      flex-direction: column;

      font-family: Kanit;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      & .green {
        color: #00b901;
      }
    }
    .menu-tab-text {
      min-height: 30px;
      margin-top: 3px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const MobileMenu = () => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    profile: { activeTab },
  } = state;

  const logout = (e) => {
    e.preventDefault();
    freespinLogout(() => {
      setUser(dispatch, '');
    }, true);
  };
  const menuChange = (item) => {
    if (item === activeTab) return false;
    setProfileMenu(dispatch, item);
  };

  return (
    <Wrap>
      <li className={activeTab === 'bets' ? 'active' : ''}>
        <Link to="/profile/" onClick={() => menuChange('bets')}>
          <IcomoonIcon icon="bets" size={30} />
          <span className="menu-tab-text">เดิมพัน</span>
        </Link>
      </li>
      <li className={activeTab === 'winning_bonuses' ? 'active' : ''}>
        <Link to="/profile/" onClick={() => menuChange('winning_bonuses')}>
          <IcomoonIcon icon="winning-bonuses" size={30} />
          <span className="menu-tab-text">โบนัสที่ชนะ</span>
        </Link>
      </li>
      <li className={activeTab === 'account_settings' ? 'active' : ''}>
        <Link to="/profile/" onClick={() => menuChange('account_settings')}>
          <IcomoonIcon icon="user-profile" size={30} />
          <span className="menu-tab-text">การตั้งค่าบัญชี</span>
        </Link>
      </li>
      <li>
        <Link to="/" onClick={logout}>
          <IcomoonIcon icon="log-out" size={30} />
          <span className="menu-tab-text">ออกจากระบบ</span>
        </Link>
      </li>
    </Wrap>
  );
};

export default MobileMenu;
